export default function ProductTableHeader() {
  return (
    <>
      <thead>
        <tr>
          <th>No</th>
          <th>Product Name</th>
          <th>Price</th>
          <th>Category</th>
          {/* <th>Action</th> */}
        </tr>
      </thead>
    </>
  );
}
